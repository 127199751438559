<template>
	<div class="monitor">
		<div class="loading" v-if="loading">
			<loading
				:active.sync="loading"
				color="#fff"
				:is-full-page="true"
				background-color="#343a40"
				loader="dots"
				:opacity="0.9"
				:z-index="50"
			/>
		</div>
		<div class="error" v-else-if="error">
			<div class="card text-white bg-danger">
				<div class="card-header">
					<h3>{{ error }}</h3>
				</div>
				<div class="card-body">
					<button class="btn btn-outline-dark" @click="fetchApps">
						Retry connection
					</button>
				</div>
			</div>
		</div>
		<div class="loaded" v-else>
			<div class="row">
				<div class="col">
					<div
						class="alert"
						:class="
							moreThanThreeHoursAgo(appsLastUpdated)
								? 'alert-danger'
								: 'alert-info'
						"
					>
						<h3>
							Apps last updated:
							<strong>{{
								dateFormat(appsLastUpdated, 'DD.MM.YYYY HH:mm')
							}}</strong>
						</h3>
					</div>
				</div>
			</div>
			<div class="row">
				<template v-if="crashedApps.length || maintenancedApps.length">
					<div class="col">
						<div class="card crashed-apps text-white bg-danger">
							<div class="card-header">
								<h3>Crashed apps ({{ crashedApps.length }})</h3>
							</div>
							<card-body :apps="crashedApps" @openApp="openApp" />
						</div>
					</div>
					<div class="col">
						<div class="card maintenanced-apps text-white bg-primary">
							<div class="card-header">
								<h3>Apps in maintenance ({{ maintenancedApps.length }})</h3>
							</div>
							<card-body :apps="maintenancedApps" @openApp="openApp" />
						</div>
					</div>
				</template>
				<div class="col" v-else>
					<div class="card total-apps text-white bg-success">
						<div class="card-header">
							<h3>No problems detected</h3>
						</div>
						<div class="card-body">
							<p>
								<strong>Total apps:</strong>
								{{ apps.length }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<modal
			name="open-app"
			height="auto"
			:clickToClose="false"
			:scrollable="true"
			:maxWidth="1000"
			:adaptive="true"
		>
			<open-app
				:app="fullApp"
				@close="closeApp"
				:runningSince="runningSince"
				@toggleIgnore="toggleIgnore"
				@toggleMaintenance="toggleMaintenance"
				@toggleDisable="toggleDisable"
			/>
		</modal>
	</div>
</template>

<script>
import Vue from 'vue'
import OctiEye from '@/services/OctiEye'
import Loading from 'vue-loading-overlay'
import OpenApp from '@/components/OpenApp'
import CardBody from '@/components/CardBody'
import { mapState } from 'vuex'

export default {
	name: 'home',
	data() {
		return {
			apps: [],
			loading: true,
			fullApp: null,
			runningSince: '',
			appsLastUpdated: '',
			playSounds: false, //not yet supported
			error: null,
		}
	},
	components: {
		Loading,
		OpenApp,
		CardBody,
	},
	timers: {
		fetchApps: { time: 30000, autostart: true, repeat: true, immediate: true },
	},
	methods: {
		async fetchApps() {
			if (!this.apps.length) {
				this.loading = true
			}
			let downCount = this.crashedApps.length
			await OctiEye.fetchApps()
				.then(response => {
					if (response.data.success) {
						this.apps = response.data.data.apps
						this.sendMails = response.data.data.sendMails
						this.runningSince = response.data.data.runningSince
						this.appsLastUpdated = response.data.data.appsLastUpdated
						if (this.crashedApps.length > downCount && this.sounds) {
							this.playSound()
						}
						this.error = null
					}
					this.loading = false
				})
				.catch(error => {
					this.loading = false
					this.error = error
					this.$notify.error(error)
				})
		},
		async openApp(app) {
			await OctiEye.openApp(app.id).then(response => {
				if (response.data.status == 'success') {
					this.fullApp = response.data.data.app
					this.$modal.show('open-app')
				}
			})
		},
		closeApp() {
			this.fullApp = null
			this.$modal.hide('open-app')
		},
		async toggleIgnore(app, value) {
			OctiEye.toggleIgnore(app.id, value).then(response => {
				this.$notify(response.data.message, response.data.status.toLowerCase())
				// console.log(response)
				if (response.data.success) {
					this.replaceApp(response.data.data.app)
				}
			})
		},
		async toggleMaintenance(app, value) {
			OctiEye.toggleMaintenance(app.id, value).then(response => {
				this.$notify(response.data.message, response.data.status.toLowerCase())
				// console.log(response)
				if (response.data.success) {
					this.replaceApp(response.data.data.app)
				}
			})
		},
		async toggleDisable(app, value) {
			OctiEye.toggleDisable(app.id, value).then(response => {
				this.$notify(response.data.message, response.data.status.toLowerCase())
				// console.log(response)
				if (response.data.success) {
					this.replaceApp(response.data.data.app)
					this.fullApp = response.data.data.app
				}
			})
		},
		replaceApp(app) {
			Vue.set(this.apps, this.apps.findIndex(a => a.id === app.id), app)
		},
		playSound() {
			let audio = new Audio(require('@/assets/sounds/alert01.mp3'))
			audio.play()
		},
		dateFormat(dateTime, format) {
			return this.$moment(dateTime).format(format)
		},
		moreThanThreeHoursAgo(dateTime) {
			return this.$moment(Date.now()).diff(dateTime, 'hours') > 3
		},
	},
	computed: {
		...mapState(['loginToken', 'sounds']),
		unignoredApps() {
			return this.apps
				.filter(app => !app.ignored)
				.sort((a, b) => a.name.localeCompare(b.name))
		},
		upApps() {
			if (!this.unignoredApps || !this.unignoredApps.length) {
				return []
			}
			return this.unignoredApps.filter(app => !app.crashed || !app.maintenance)
		},
		crashedApps() {
			if (!this.unignoredApps || !this.unignoredApps.length) {
				return []
			}
			return this.unignoredApps.filter(app => app.crashed)
		},
		maintenancedApps() {
			if (!this.unignoredApps || !this.unignoredApps.length) {
				return []
			}
			return this.unignoredApps.filter(app => app.maintenance)
		},
	},
	watch: {
		loginToken(value) {
			if (value) {
				this.fetchApps()
			}
		},
	},
}
</script>
