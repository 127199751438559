<template>
	<div class="card-body">
		<p v-for="app in apps" :key="app.id" @click="$emit('openApp', app)">
			{{ app.name }}
		</p>
	</div>
</template>

<script>
export default {
	name: 'card-body',
	props: {
		apps: Array,
	},
}
</script>

<style lang="less" scoped>
.card-body {
	p {
		cursor: pointer;
	}
}
</style>
